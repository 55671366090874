<template>
  <!-- Items for this company -->
  <div class="item-grid">
    <div
      v-for="(item, index) in items"
      :key="index"
    >
      <div :class="['box', { 'selected-box': selectedItem === item.shop_item_id }]">
        <div class="box-header">
          <label class="custom-radio">
            <input
              :name="`radio-select-${company.company_id}`"
              type="radio"
              :value="item.shop_item_id"
              v-model="selectedItem"
              @change="selected"
            />
            <span class="custom-radio-button"></span>
            <span class="name-label">{{ item.name }}</span>
          </label>
          <span class="price">{{ item.amount }} {{ item.currency }}</span>
        </div>
        <div class="description">{{ item.description }}</div>
        <div class="date-range">{{ item.dateFrom }} {{ $t('PUBLIC.FORM.TO') }} {{ item.dateTo }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FormShopMembershipSelection',
  props: ['company', 'items'],
  watch: {
    /*items: {
      handler(newValue) {
        const selected_item = newValue.find(item => item.selected);

        this.selectedItem = selected_item ? selected_item.shop_item_id : null;
      },
      deep: true
    }*/
  },
    
  methods: {
    selected() {
      console.log(this.selectedItem);
      const item = this.items.find(item => item.shop_item_id === this.selectedItem);
      this.$emit('selected_membership', this.company.company_id, item, this.selectedItem, this.previousSelectedItem)

      this.previousSelectedItem = this.selectedItem;
    }
  },
  data() {
    return {
      previousSelectedItem: null,
      selectedItem: null, // To store selected item IDs
      /*items: [
        {
          shop_item_id: 1,
          name: 'Item 1 from Company A',
          amount: '20',
          currency: 'SEK',
          description: 'Description for item 1 from company A, this is a longer description',
          dateFrom: '2024-01-01',
          dateTo: '2024-12-31',
        }
      ],*/
    };
  },
};
</script>

<style scoped>

.name-label {
  font-weight: bold;
  margin-left: 5px;
}

.item-selector {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.company-title {
  font-size: 22px;
  margin: 20px 0;
  text-align: left;
  color: #333;
  border-bottom: 2px solid #ccc;
  padding-bottom: 5px;
}

/* Grid for items */
.item-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 3 columns on desktop */
  gap: 20px;
}

.box {
  padding: 20px;
  border: 1px solid #ececec;
  border-radius: 2px;
  position: relative;
  transition: border-color 0.3s;
}

.selected-box {
  border-color: #2D9BF0; /* Highlight color when selected */
  background-color: #f6fdff;
}

.box-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.price {
  font-weight: bold;
}

.description {
  margin-top: 10px;
  font-size: 14px;
  color: #555;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 4.5em;
  height: 4.5em;
}

.date-range {
  margin-top: 20px;
  text-align: center;
  color: #2D9BF0; /* Color for the date range */
  font-style: italic;
}

/* Custom Radio Button */
.custom-radio {
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
}

.custom-radio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.custom-radio-button {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid gray;
  background-color: white;
  margin-right: 10px;
  display: inline-block;
  position: relative;
  transition: background-color 0.3s, border-color 0.3s;
}

.custom-radio input:checked + .custom-radio-button {
  background-color: #2D9BF0; /* Blue background for selected radio */
  border-color: #2D9BF0;
}

.custom-radio-button::after {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  background-color: white;
  opacity: 0;
  transition: opacity 0.3s;
}

.custom-radio input:checked + .custom-radio-button::after {
  opacity: 1;
}

@media (max-width: 768px) {
  /* 1 column layout on mobile */
  .item-grid {
    grid-template-columns: 1fr;
  }

  .item-selector {
    padding: 0px;
  }
}
</style>
