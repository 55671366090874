<template>
  <div class="cart-summary">
    <h2>{{ $t('PUBLIC.FORM.CART') }}</h2>

    <div v-if="cart_items.length > 0">
      <div class="cart-item" v-for="(item, index) in cart_items" :key="index">
        <img :src="(item.img_src || '/assets/images/membership_select.png')" alt="item image" class="cart-item-image" />
        
        <div class="cart-item-details">
          <h3 class="cart-item-title">{{ item.name }}</h3>
          <p class="cart-item-description">{{ item.description }}</p>
        </div>

        <div class="cart-item-price-container">
          <span class="cart-item-price">{{ item.price }}</span>
          <a
            class="btn btn-icon btn-light btn-sm mx-2"
            @click.prevent="removeItem(index, item)"
          >
            <span class="svg-icon svg-icon-md svg-icon-primary">
              <inline-svg src="/assets/svg/Trash.svg"></inline-svg>
            </span>
          </a>
          
        </div>
      </div>

      <div class="cart-total">
        <span>{{ $t('PUBLIC.FORM.TOTAL') }}</span>
        <span>{{ totalPrice }}</span>
      </div>
    </div>

    <div v-else>
      <p>{{ $t('PUBLIC.FORM.CART_EMPTY') }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FormShopCartSummaryTable',
  props: {
    cart_items: {
      type: Array,
      required: true,
    },
  },
  computed: {
    totalPrice() {
      return this.cart_items.reduce((total, item) => total + parseFloat(item.amount), 0).toFixed(2);
    },
  },
  methods: {
    removeItem(index, item) {
      this.$emit("remove-item", index, item);
    },
  },
};
</script>
<style scoped>

.cart-summary {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 2px;
  background-color: #ffffff;
}

h2 {
  font-size: 24px;
  margin-bottom: 20px;
}

.cart-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
  border-bottom: 1px solid #ddd;
}

.cart-item-image {
  width: 50px;
  height: 50px;
  object-fit: cover;
  margin-right: 15px;
}

.cart-item-details {
  flex: 1;
  min-width: 0;
  padding-right: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.cart-item-title {
  font-size: 16px;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%; /* Adjust this based on your design */
}

.cart-item-description {
  font-size: 14px;
  color: #555;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%; /* Adjust this based on your design */
}

.cart-item-price-container {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
}

.cart-item-price {
  font-weight: bold;
  font-size: 16px;
}

.cart-item-remove {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 18px;
  color: #d9534f;
}

.cart-total {
  display: flex;
  justify-content: space-between;
  padding: 15px;
  margin-top: 20px;
  background-color: #e3eff3;
  font-weight: bold;
  font-size: 18px;
  border-radius: 5px;
}

@media (max-width: 768px) {
  .cart-item-title,
  .cart-item-description {
    max-width: 100px; /* Limit width on mobile to avoid overflow */
  }

  .cart-item-price-container {
    justify-content: flex-end;
  }
}
</style>