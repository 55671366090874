<template>
  <!-- Extra selections, such as donations -->
  <div class="extra-selection">
    <div class="item-grid">
      <div
        v-for="(item, index) in (extra_items || sample_extra_items)"
        :key="index"
        :class="['box', { 'selected-box': selectedItems.includes(item.shop_item_id) }]"
      >
        <div class="image-container">
          <img :src="item.img_url" alt="item image" class="item-image" />
        </div>

        <div class="box-header">
          <label class="custom-checkbox">
            <input
              type="checkbox"
              :value="item.shop_item_id"
              v-model="selectedItems"
              @change="selected"
            />
            <span class="checkmark"></span>
            <!-- Display item name next to the checkbox -->
            <span class="item-name">{{ item.name }}</span>
          </label>

          <!-- Conditional rendering for CUSTOM_DONATION -->
          <div v-if="item.type === 'CUSTOM_DONATION'">
            <memlist-text-input
              id="company_select_labels0"
              name="company_select_labels0"
              layout="vertical"
              validation_type="TEXT"

              v-model="amount"

              :placeholder="$t('PUBLIC.FORM.CUSTOM_DONATION_AMOUNT')"
              :required="false"
              
              @validated="validated"
              @update:value="update_form"
              >
            </memlist-text-input>
            
          </div>
          <span v-else class="price">{{ item.amount }} {{ item.currency }}</span>
        </div>
        
        <div class="description">{{ item.description }}</div>

        <button @click="add_item(item.shop_item_id)" v-if="!selectedItems.includes(item.shop_item_id)" class="extra-item-button">{{ $t('PUBLIC.FORM.ADD') }}</button>
        <button @click="remove_item(item.shop_item_id)" v-else class="extra-item-button-red">{{ $t('PUBLIC.FORM.REMOVE') }}</button>

      </div>
    </div>
  </div>
</template>


<script>
export default {
  props: ['extra_items'],
  watch: {
    extra_items: {
      handler(newValue) {
        this.selectedItems = newValue.filter(item => item.selected).map((item) => item.shop_item_id);
      },
      deep: true
    }
  },
  methods: {
    add_item(shop_item_id) {
      this.selectedItems.push(shop_item_id);
      this.$emit('selected', this.selectedItems);
    },
    remove_item(shop_item_id) {
      this.selectedItems = this.selectedItems.filter(item => item !== shop_item_id);
      this.$emit('selected', this.selectedItems);
    },
    selected() {
      this.$emit('selected', this.selectedItems)
    }
  },
  data() {
    return {
      amount: null,
      customDonationAmounts: {},
      selectedItems: [], // To store selected extra item IDs
      sample_extra_items: [
        {
          shop_item_id: 1,
          name: 'Extra Item 1',
          selected: false,
          amount: '5',
          currency: 'SEK',
          description: 'Description for extra item 1',
          img_url: 'http://localhost:3020/dlfile/7TZe1m6WW6kthf397sUy5OFPSKUvKGH6Cwa6hXW4QrbdNSbkL0pqo9EwFyLIr0qZ',
        },
        {
          shop_item_id: 2,
          name: 'Extra Item 2',
          selected: false,
          amount: '10',
          currency: 'SEK',
          description: 'Description for extra item 2',
          img_url: 'https://via.placeholder.com/128',
        },
        {
          shop_item_id: 3,
          name: 'Extra Item 3',
          selected: false,
          amount: '15',
          currency: 'SEK',
          description: 'Description for extra item 3',
          img_url: 'https://via.placeholder.com/128',
        },
      ],
    };
  },
};
</script>

<style scoped>

.extra-item-button {
  border: 2px solid #2D9BF0; /* Your blue color */
  background-color: white;
  color: #2D9BF0; /* Your blue text color */
  padding: 8px 14px;
  font-size: 12px;
  font-weight: bold;
  border-radius: 4px;
  cursor: pointer;
  margin-top: auto; /* Pushes the button to the bottom */
  display: block;
  text-align: center;
  width: 100%; /* Ensures it's flexible for small screens */
  max-width: 180px; /* Limit the button width to 200px */
  margin-left: auto;
  margin-right: auto; /* Centers the button */
  margin-top: 20px;
}

.extra-item-button:hover {
  background-color: #2D9BF0;
  color: white;
}


.extra-item-button-red {
  border: 2px solid #f02d47; /* Your blue color */
  background-color: white;
  color: #f02d47; /* Your blue text color */
  padding: 8px 14px;
  font-size: 12px;
  font-weight: bold;
  border-radius: 4px;
  cursor: pointer;
  margin-top: auto; /* Pushes the button to the bottom */
  display: block;
  text-align: center;
  width: 100%; /* Ensures it's flexible for small screens */
  max-width: 180px; /* Limit the button width to 200px */
  margin-left: auto;
  margin-right: auto; /* Centers the button */
  margin-top: 20px;
}

.extra-item-button-red:hover {
  background-color: #f02d47;
  color: white;
}


.extra-selection {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0;
}

/* Grid for items */
.item-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 3 columns on desktop */
  gap: 20px;
}

.box {
  padding: 20px;
  border: 1px solid #ececec;
  border-radius: 2px;
  position: relative;
  transition: border-color 0.3s;
}

.selected-box {
  border-color: #2D9BF0; /* Highlight color when selected */
}

/* Image container */
.image-container {
  width: 100%;
  height: 128px;
  overflow: hidden;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.item-image {
  height: 100%;
  object-fit: cover;
}

.box-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.price {
  font-weight: bold;
}

.description {
  margin-top: 10px;
  font-size: 14px;
  color: #999999;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 4.5em;
}

/* Custom Checkbox */
.custom-checkbox {
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
}

.custom-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.custom-checkbox .checkmark {
  width: 20px;
  height: 20px;
  background-color: white;
  border: 2px solid #999999;
  border-radius: 4px; /* Keep it square with rounded corners */
  margin-right: 10px;
  transition: background-color 0.3s, border-color 0.3s;
}

.custom-checkbox input:checked + .checkmark {
  background-color: #2D9BF0; /* Blue when checked */
  border-color: #2D9BF0;
}

.custom-checkbox input:checked + .checkmark:after {
  content: "";
  position: absolute;
  left: 7px;
  top: 3px;
  width: 6px;
  height: 12px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.item-name {
  font-weight: bold;
  margin-left: 5px;
}

/* Responsive design */
@media (max-width: 768px) {
  /* 1 column layout on mobile */
  .item-grid {
    grid-template-columns: 1fr;
  }

  .extra-selection {
    padding: 0px;
  }
}
</style>
