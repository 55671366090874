<template>
  <div class="item-selector">
    
    <div v-for="(company, companyIndex) in (companies || sample_companies)" :key="companyIndex">
      
      <!-- Company Title -->
      <h2 class="company-title">{{ company.name }}</h2>

      <!-- Error Box Wrapper -->
      <div :class="['form-container', { 'error-box': company.show_error }]">
        <div v-if="company.show_error" class="error-label">{{ $t('PUBLIC.FORM.MUST_SELECT_MEMBERSHIP') }}</div>

        <!-- Membership Selection Component -->
        <FormShopMembershipSelection 
          :company="company"
          :items="company.items"
          @selected_membership="selected_membership"
        />
      </div>

    </div>
  </div>
</template>

<script>
import FormShopMembershipSelection from './FormShopMembershipSelection.vue';


export default {
  name: 'FormShopCompanyhipSelection',
  props: ['companies'],
  components: { FormShopMembershipSelection },
  methods: {
    selected_membership(company_id, shop_item, shop_item_id, previous_shop_item_id) {
      this.$emit('selected_membership', company_id, shop_item, shop_item_id, previous_shop_item_id);
    }
  },
  data() {
    return {
      selectedItems: [], // To store selected item IDs
      /*companies: [
        {
          name: "Sample A",
          company_id: 'a',
          show_error: false,
          items: [
            {
              id: 1,
              name: 'Item 1 from Company A',
              amount: 1,
              currency: 'SEK',
              description: 'Description for item 1 from company A, this is a longer description, this is a longer description, this is a longer description this is a longer description.',
              dateFrom: '2024-01-01',
              dateTo: '2024-12-31',
            },
          ],
        },
      ],*/
    };
  },
};
</script>

<style scoped>

.error-box {
  border: 2px solid #ff6262; /* Error border */
  padding: 10px;
  position: relative;
}

.error-label {
  background-color: #ff6262;
  color: white;
  height: 30px;
  display: flex;
  align-items: center;
  padding-left: 10px;
  margin: 0; /* No margin to the error box */
}

.item-selector {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.company-title {
  font-size: 22px;
  margin-top: 20px;
  text-align: left;
  color: #333;
  padding-bottom: 5px;
}

/* Grid for items */
.item-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* 2 columns on desktop */
  gap: 20px;
}

.box {
  padding: 20px;
  border: 2px solid #ccc;
  border-radius: 5px;
  position: relative;
  transition: border-color 0.3s;
}

.selected-box {
  border-color: #2D9BF0; /* Highlight color when selected */
}

.box-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.price {
  font-weight: bold;
}

.description {
  margin-top: 10px;
  font-size: 14px;
  color: #555;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 4.5em;
}

.date-range {
  margin-top: 20px;
  text-align: center;
  color: #2D9BF0; /* Color for the date range */
  font-weight: bold;
}

/* Custom Radio Button */
.custom-radio {
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
}

.custom-radio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.custom-radio-button {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid gray;
  background-color: white;
  margin-right: 10px;
  display: inline-block;
  position: relative;
  transition: background-color 0.3s, border-color 0.3s;
}

.custom-radio input:checked + .custom-radio-button {
  background-color: #2D9BF0; /* Blue background for selected radio */
  border-color: #2D9BF0;
}

.custom-radio-button::after {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  background-color: white;
  opacity: 0;
  transition: opacity 0.3s;
}

.custom-radio input:checked + .custom-radio-button::after {
  opacity: 1;
}

@media (max-width: 768px) {
  /* 1 column layout on mobile */
  .item-grid {
    grid-template-columns: 1fr;
  }

  .item-selector {
    padding: 0;
  }
}
</style>
